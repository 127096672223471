<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Create Banner</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Name"
                      v-model="name"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.name.$errors)
                      "
                      @blur="v$.name.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Sub title</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Sub title"
                      v-model="sub_title"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.sub_title.$errors)
                      "
                      @blur="v$.sub_title.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Status</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Banner Status"
                      :items="bannerStatuses"
                      item-text="value"
                      item-value="key"
                      v-model="status"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.status.$errors)
                      "
                      @blur="v$.status.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Type</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Banner Type"
                      :items="bannerTypes"
                      item-text="value"
                      item-value="key"
                      v-model="type"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.type.$errors)
                      "
                      @blur="v$.type.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col
                    v-if="type == 'hamper' || type == 'sale'"
                    cols="12"
                    md="6"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Description</label
                    >
                    <v-textarea
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Description"
                      v-model="description"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.description.$errors)
                      "
                      @blur="v$.description.$touch"
                    >
                    </v-textarea>
                  </v-col>

                  <v-col
                    v-if="type == 'hamper' || type == 'sale'"
                    cols="12"
                    md="6"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Start Date</label
                    >

                    <v-menu
                      ref="startDateMenu"
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="startDate"
                          placeholder="Select start date..."
                          prepend-icon="ni ni-calendar-grid-58"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="input-style font-size-input text-light-input placeholder-light"
                          :error-messages="
                            getFieldValidationErrors(v$.startDate.$errors)
                          "
                          @input="v$.startDate.$touch"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        @input="startDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    v-if="type == 'hamper' || type == 'sale'"
                    cols="12"
                    md="6"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >End Date</label
                    >

                    <v-menu
                      ref="endDateMenu"
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="endDate"
                          placeholder="Select end date..."
                          prepend-icon="ni ni-calendar-grid-58"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="input-style font-size-input text-light-input placeholder-light"
                          :error-messages="
                            getFieldValidationErrors(v$.endDate.$errors)
                          "
                          @input="v$.endDate.$touch"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        @input="endDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col v-if="type == 'hamper'" cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Hamper Retail Price</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Hamper Retail Price"
                      v-model="hamperRetailPrice"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.hamperRetailPrice.$errors)
                      "
                      @blur="v$.hamperRetailPrice.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col v-if="type == 'hamper'" cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Hamper Stock on Hand</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Hamper Quantity"
                      v-model="hamperQuantity"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.hamperQuantity.$errors)
                      "
                      @blur="v$.hamperQuantity.$touch"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      @click="submit"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      :loading="submitButton.isLoading"
                      class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                      color="#5e72e4"
                      >Submit</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { numeric, required, requiredIf } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "banners-create",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  validations() {
    return {
      name: { required },
      sub_title: { required },
      description: {
        required: requiredIf(() => {
          return this.type == "hamper";
        }),
      },
      status: { required },
      type: { required },
      endDate: {
        required: requiredIf(() => {
          return this.type == "hamper" || this.type == "sale";
        }),
      },
      startDate: {
        required: requiredIf(() => {
          return this.type == "hamper" || this.type == "sale";
        }),
      },
      hamperRetailPrice: {
        numeric,
        required: requiredIf(() => {
          return this.type == "hamper";
        }),
      },
      hamperQuantity: {
        numeric,
        required: requiredIf(() => {
          return this.type == "hamper";
        }),
      },
    };
  },
  props: {},
  data() {
    return {
      fileToUpload: null,
      fileToUploadDesignation: "",
      name: "",
      sub_title: "",
      description: "",
      status: "",
      type: "",
      endDateMenu: null,
      endDate: "",
      startDateMenu: null,
      startDate: "",
      hamperRetailPrice: "",
      hamperQuantity: 0,
      imageUrl: require("@/assets/img/placeholder-200x200.png"),
      submitButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const bannerObject = {
        title: this.name,
        sub_title: this.sub_title,
        description: this.description,
        status: this.status,
        type: this.type,
        end_date: this.endDate,
        start_date: this.startDate,
        quantity: this.hamperQuantity,
        retail_price: this.hamperRetailPrice,
      };

      this.$http
        .post("/banners", bannerObject, this.sessionStore.getHttpConfig)
        .then((result) => {
          this.$notify.success("Banner successfully updated!");
          this.$router.push(`/banners/edit/${result.data.data[0].id}`);
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while updating the Banner. Please try again later or contact support."
          );
          console.error("Error while updating the Banner: ", error);
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },
  },
  mounted() {},
};
</script>
